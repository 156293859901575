.root {
  display: inline-flex;
  align-items: center;
  padding: 0;
  border: 0;
  background: transparent;
  color: inherit;
  font-family: var(--font-family-headline);
  font-size: var(--text-body-m);
  text-decoration: none;
  appearance: none;
  gap: 0.25em;
}

.root.primary {
  color: var(--color-accent-default);
}

.root:hover .text {
  text-decoration: underline;
}
