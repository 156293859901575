.root {
  --notch-size: 5px;

  position: fixed;
  top: var(--space-2xl-3xl);
  left: 50%;
  z-index: var(--z-index-preview-banner);
  padding: var(--space-3xs) var(--space-2xs) var(--space-3xs) var(--space-xs);
  transform: translateX(-50%);
  background: var(--color-background-default);
  font-family: var(--font-family-mono);
  font-size: var(--text-body-s);

  clip-path: polygon(
    0% var(--notch-size),
    var(--notch-size) 0%,
    calc(100% - var(--notch-size)) 0%,
    100% var(--notch-size),
    100% calc(100% - var(--notch-size)),
    calc(100% - var(--notch-size)) 100%,
    var(--notch-size) 100%,
    0% calc(100% - var(--notch-size))
  );
}

.paragraph {
  display: flex;
  gap: var(--space-s);
}
