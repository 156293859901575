:root {
  /* Base colors (old) */
  --base-color-white: #fff;
  --base-color-blue-100: #e6f0ff;
  --base-color-blue-200: #c2d6f9;
  --base-color-blue-500: #2a6fe1;
  --base-color-blue-600: #1f4f9e;
  --base-color-grey-50: #fafafb;
  --base-color-grey-100: #f1f3f6;
  --base-color-grey-200: #ebeff1;
  --base-color-grey-300: #e6eaec;
  --base-color-grey-400: #b4c0c6;
  --base-color-grey-500: #8497a2;
  --base-color-grey-600: #5f7886;
  --base-color-grey-900: #092f45;
  --base-color-grey-1000: #031c2b;
  --base-color-purple-100: #f1eaff;
  --base-color-purple-200: #e4d8ff;
  --base-color-purple-500: #915eff;
  --base-color-purple-600: #7b4bdf;

  /* Base colors (new) */
  --brand-color-white: #ffffff;
  --brand-color-black: #0a1b2a;
  --brand-color-blue-0: #fafcff;
  --brand-color-blue-1: #ebf2fc;
  --brand-color-blue-2: #dfebfe;
  --brand-color-blue-3: #b7d2ff;
  --brand-color-blue-4: #90b7f6;
  --brand-color-blue-5: #6ca3fe;
  --brand-color-blue-6: #498af6;
  --brand-color-blue-7: #2a6fe1;
  --brand-color-blue-8: #134596;
  --brand-color-blue-9: #112b5e;
  --brand-color-blue-10: #0c1325;
  --brand-color-green-0: #f4fef9;
  --brand-color-green-1: #e2fcf0;
  --brand-color-green-2: #c4f4e0;
  --brand-color-green-3: #85dabb;
  --brand-color-green-4: #57c7a4;
  --brand-color-green-5: #1bb694;
  --brand-color-green-6: #00a084;
  --brand-color-green-7: #007c68;
  --brand-color-green-8: #005346;
  --brand-color-green-9: #03322b;
  --brand-color-green-10: #02120f;
  --brand-color-grey-0: #f9fcff;
  --brand-color-grey-1: #eff3fa;
  --brand-color-grey-2: #e1eaf3;
  --brand-color-grey-3: #c5d4de;
  --brand-color-grey-4: #a5b7c5;
  --brand-color-grey-5: #8ea5b5;
  --brand-color-grey-6: #768fa2;
  --brand-color-grey-7: #5b798a;
  --brand-color-grey-8: #26465b;
  --brand-color-grey-9: #092f45;
  --brand-color-grey-10: #02121e;
  --brand-color-purple-0: #fbf9ff;
  --brand-color-purple-1: #f6f2ff;
  --brand-color-purple-2: #f0e5ff;
  --brand-color-purple-3: #dcc7fc;
  --brand-color-purple-4: #bd9eff;
  --brand-color-purple-5: #af8fff;
  --brand-color-purple-6: #915eff;
  --brand-color-purple-7: #7a52db;
  --brand-color-purple-8: #5527a4;
  --brand-color-purple-9: #381a6b;
  --brand-color-purple-10: #0f0522;
  --brand-color-red-0: #fff8fa;
  --brand-color-red-1: #fff2f5;
  --brand-color-red-2: #ffe3e8;
  --brand-color-red-3: #fcc0cb;
  --brand-color-red-4: #fd93a8;
  --brand-color-red-5: #ff6183;
  --brand-color-red-6: #eb5070;
  --brand-color-red-7: #b73b55;
  --brand-color-red-8: #7f243a;
  --brand-color-red-9: #541025;
  --brand-color-red-10: #1d050b;
  --brand-color-salmon-0: #fffbf9;
  --brand-color-salmon-1: #fff4ef;
  --brand-color-salmon-2: #ffe4d8;
  --brand-color-salmon-3: #fec3ab;
  --brand-color-salmon-4: #eca283;
  --brand-color-salmon-5: #ed8565;
  --brand-color-salmon-6: #db6c53;
  --brand-color-salmon-7: #b7453b;
  --brand-color-salmon-8: #7f251f;
  --brand-color-salmon-9: #4a1d1a;
  --brand-color-salmon-10: #1d0505;
  --brand-color-yellow-0: #fefbf6;
  --brand-color-yellow-1: #fdf6e8;
  --brand-color-yellow-2: #ffe5b4;
  --brand-color-yellow-3: #ffd482;
  --brand-color-yellow-4: #e0aa40;
  --brand-color-yellow-5: #d8982a;
  --brand-color-yellow-6: #c08000;
  --brand-color-yellow-7: #9c640e;
  --brand-color-yellow-8: #6d4500;
  --brand-color-yellow-9: #3f2604;
  --brand-color-yellow-10: #1b0b00;

  /* Named colors (according to their use) 
   * These currently use the old base colors, but we should slowly
   * start transitioning to the new brand colors above.
   */
  --color-background-default: var(--base-color-white);
  --color-background-light: var(--base-color-grey-100);
  --color-background-faint: var(--base-color-grey-50);
  --color-background-inverted: var(--base-color-grey-1000);
  --color-background-card: var(--base-color-white);
  --color-background-alert: #ffeff3;
  --color-text-default: var(--base-color-grey-900);
  --color-text-secondary: var(--base-color-grey-600);
  --color-text-tertiary: var(--base-color-grey-500);
  --color-text-inverted-default: var(--base-color-white);
  --color-accent-default: var(--base-color-blue-500);
  --color-accent-hover: var(--base-color-blue-600);
  --color-accent-subdued: var(--base-color-blue-100);
  --color-text-on-color: var(--base-color-white);
  --color-text-alert: #b83b54;
  --color-border: rgb(9 47 69 / 10%);
  --color-border-subdued: rgb(9 47 69 / 5%);
  --color-border-accent: var(--base-color-blue-200);
  --color-overlay: rgb(9 47 69 / 85%);
  --color-input-border: var(--base-color-grey-300);
  --color-border-alert: #fddfe7;
  --color-input-border-alert: #d6919f;
  --color-table-border: var(--color-border);
  --color-table-stripe: var(--color-background-light);
  --color-teal: #85dabb;
  --color-teal-hover: #76ccad;
  --color-salmon: #eca283;
  --color-salmon-hover: #de9070;
  --color-salmon-dark: #de7056;
  --color-yellow: #ffd482;
  --color-yellow-hover: #eec26f;
  --color-purple: var(--base-color-purple-500);
  --color-purple-hover: var(--base-color-purple-600);
  --color-alert: #ff0c46;
  --color-success: #00c48c;

  /* Data Viz Colors */
  --visualization-color-primary: var(--base-color-blue-500);
  --visualization-color-secondary: var(--base-color-grey-400);

  /* Text Sizes */
  --text-body-2xs: fluid(8.5px, 10.5px);
  --text-body-xs: fluid(10px, 12px);
  --text-body-s: fluid(12px, 14px);
  --text-body-m: fluid(14px, 16px);
  --text-body-l: fluid(16px, 18px);
  --text-body-xl: fluid(18px, 22px);
  --text-headline-xs: fluid(16px, 20px);
  --text-headline-s: fluid(18px, 24px);
  --text-headline-m: fluid(20px, 32px);
  --text-headline-l: fluid(26px, 40px);
  --text-headline-xl: fluid(32px, 54px);
  --text-headline-2xl: fluid(38px, 80px);
  --text-headline-3xl: fluid(52px, 88px);

  /* Text Widths */
  --text-measure-ultra-narrow: 24ch;
  --text-measure-narrow: 35ch;
  --text-measure-medium: 50ch;
  --text-measure-wide: 70ch;

  /* Font Families
   * These are moved inside src/pages/_app.jsx using next/font
   */

  /* Spacing */
  --space-4xs: fluid(2px, 4px);
  --space-3xs: fluid(3px, 6px);
  --space-2xs: fluid(6px, 8px);
  --space-xs: fluid(8px, 12px);
  --space-s: fluid(12px, 16px);
  --space-m: fluid(16px, 24px);
  --space-l: fluid(24px, 32px);
  --space-xl: fluid(32px, 48px);
  --space-2xl: fluid(48px, 64px);
  --space-3xl: fluid(64px, 96px);
  --space-4xl: fluid(96px, 118px);
  --space-5xl: fluid(120px, 160px);
  --space-6xl: fluid(204px, 272px);

  /* Spacing Pairs */
  --space-2xs-xs: fluid(6px, 12px);
  --space-xs-s: fluid(8px, 16px);
  --space-s-m: fluid(12px, 24px);
  --space-m-l: fluid(16px, 32px);
  --space-l-xl: fluid(24px, 48px);
  --space-xl-2xl: fluid(32px, 64px);
  --space-2xl-3xl: fluid(48px, 96px);
  --space-3xl-4xl: fluid(64px, 128px);

  /* Define more spacing pairs here */

  /* Named Spacing */
  --card-spacing: var(--space-l);
  --banner-height: 0px;
  --nav-height: 70px;
  --blockquote-padding: 48px;

  /* Border Radius */
  --border-radius-small: 6px;
  --border-radius-default: 12px;
  --border-radius-large: 16px;

  /* Transition */
  --transition-default: 0.3s ease-in-out;
  --transition-slow: 0.5s ease-in-out;
  --transition-fast: 0.2s ease-in-out;

  /* Layout variables */
  --layout-max-width: 1800px;
  --layout-max-width-s: 1280px;
  --row-gutter: var(--space-m);
  --column-gutter: var(--space-m);
  --layout-gutter: fluid(20px, 64px);
  --layout-gutter-s: var(--layout-gutter);
  --grid-columns: 2;
  --layout-sticky-top-offset: 0px;
  --z-index-overlay: 999;
  --z-index-modal: 1000;
  --z-index-preview-banner: 3;
  --scrollbar-size: 10px;
  --blog-hero-overlap: 56px;
  --long-form-article-width: 680px;
}

@media (--small) {
  :root {
    --grid-columns: 12;
    --card-spacing: var(--space-m);
  }
}

@media (--medium) {
  :root {
    --card-spacing: var(--space-m-l);
  }
}

@media (--large) {
  :root {
    --nav-height: 80px;
  }
}

@media (--max) {
  :root {
    --text-body-xs: 13px;
  }
}

@media (--layout-s-full) {
  :root {
    --layout-gutter-s: calc((100vw - 1280px) / 2);
  }
}

@media (--layout-full) {
  :root {
    --layout-gutter: calc((100vw - 1800px) / 2);
  }
}
